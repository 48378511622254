import React from 'react'

const footer = () => {
  return (
    <footer className='page-footer'>
      <p> &copy; {new Date().getFullYear()}: Bengali Plates</p>
    </footer>
  )
}

export default footer
